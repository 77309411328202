import {createAction} from "@reduxjs/toolkit";
import {doFetch} from "../../api/dofetch";
import BackendApi from "../../api/backend"
import { Routes } from "../../app/routes"
import {a_setToastOpen} from "../../common/state/actions";
import {TOAST_SEV, TOAST_TYPES} from "../../common/components/Toast";

/**
 * ======================================================
 * THUNK ACTIONS
 * ======================================================
 */
export function a_auth(authRequest, successRedirect, failRedirect, history) {
    return (dispatch, getState) => {
        return doFetch(BackendApi.AUTH, dispatch, { body: authRequest, noLogout: true })
            .then(json => {
                dispatch(a_setUser(json))
                history.replace(successRedirect)
            })
            .catch(err => {
                if (err && err.display) {
                    dispatch(a_setToastOpen(TOAST_TYPES.ROOT_TOAST, err.statusMessage, TOAST_SEV.ERROR, true))
                }
                dispatch(a_setUser('_empty_'))
                history.replace(failRedirect)
            })
    }
}

export function a_logout() {
    return dispatch => {
        return doFetch(BackendApi.LOGOUT, dispatch)
            .finally(() => {
                dispatch(a_setUser('_empty_'))
                window.location = Routes.LOGOUT.pathname
            })
    }
}


/**
 * ======================================================
 * STATIC ACTIONS
 * ======================================================
 */
export const a_setUser = createAction('setUser')


