import {createReducer} from '@reduxjs/toolkit';

/**
 * ======================================================
 * REDUCER
 * ======================================================
 */
const attendantStore = createReducer({isLoading: true, attendants: null}, {
    setAttendants: (state, action) => {
        return {
            ...state,
            isLoading: false,
            attendants: action.payload
        }
    },
    removeAttendant: (state, action) => {
        const attendantId = action.payload
        return {
            ...state,
            isLoading: false,
            attendants: state.attendants.filter(attendant => attendant.id !== attendantId)
        }
    },
    addAttendant: (state, action) => {
        return {
            ...state,
            isLoading: false,
            attendants: state.attendants.concat(action.payload)
        }

    },
    setLoadingAttendants: (state, action) => {
        return {
            ...state,
            isLoading: action.payload
        }
    }
})

/**
 * ======================================================
 * HELPERS
 * ======================================================
 */

export default attendantStore
