import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import MaterialDialog from "@material-ui/core/Dialog";
import {a_setDialogClosed} from "../state/actions";

export const DIALOG_IDS = {
    TERMS: 'TERMS'
}

const Dialog = (props) => {
    const { id, children } = props
    let dialogState = useSelector(state => state.dialogStateStore)[id]
    const dispatch = useDispatch()

    const onClose = useCallback(() => {
        dispatch(a_setDialogClosed(id))
    }, [dispatch, id])

    if (!dialogState) {
        return (<div></div>)
    }

    return (
        <MaterialDialog
            open={dialogState.openState}
            onClose={onClose}
            scroll="paper"
            maxWidth="md"
        >
            <DialogContent dividers={true}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </MaterialDialog>
    )

}

export default Dialog
