import React, {useCallback, useEffect} from "react";
import P2DCard from "../../common/components/P2DCard"
import albumImage from "../../assets/images/p2d_small.png";
import {useDispatch, useSelector} from "react-redux";
import {a_requestBackupState, a_updateBackupState} from "../state/actions";
import {
    BackupContainer, BackupControlsParent,
    BackupControlsToggle,
    BackupFolder,
    BackupFolderStatus,
    BackupToggle,
    BackupToggleParent
} from "./Backup.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from "@material-ui/icons/Refresh";
import folder from "../../assets/images/folder.png";
import Link from "@material-ui/core/Link";
import HelpPopover from "../../common/components/HelpPopover";

const Backup = () => {

    const dispatch = useDispatch()
    const {isLoading, backupState} = useSelector(state => state.backupStateStore)
    const [backupTab, setBackupTab] = React.useState(0)
    const [time, setTime] = React.useState(Date.now())

    const handleTabChange = (event, newValue) => {
        setBackupTab(newValue);
    };

    useEffect(() => {
        dispatch(a_requestBackupState())
    }, [dispatch]);

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const onEnableDisableBackup = useCallback((event) => {
        dispatch(a_updateBackupState(event.target.checked, backupState.findUpdated, backupState.deleteRemoved, backupState.createAlbums))
    }, [dispatch, backupState])

    const onEnableDisableFindUpdated = useCallback((event) => {
        dispatch(a_updateBackupState(backupState.backupEnabled, event.target.checked, backupState.deleteRemoved, backupState.createAlbums))
    }, [dispatch, backupState])

    const onEnableDisableDeleteRemoved = useCallback((event) => {
        dispatch(a_updateBackupState(backupState.backupEnabled, backupState.findUpdated, event.target.checked, backupState.createAlbums))
    }, [dispatch, backupState])

    const onEnableDisableCreateAlbums = useCallback((event) => {
        dispatch(a_updateBackupState(backupState.backupEnabled, backupState.findUpdated, backupState.deleteRemoved, event.target.checked))
    }, [dispatch, backupState])

    const onRefresh = useCallback(() => {
        dispatch(a_requestBackupState())
    }, [dispatch])

    if (isLoading || !backupState)
        return (<BackupContainer><CircularProgress/></BackupContainer>);

    const statusLine = buildStatusLine(backupState)

    return (
        <P2DCard
            cardImage={albumImage}
            cardTitle="Backup"
            cardStatus={statusLine}
            cardActions={(
                <BackupToggleParent>
                    {backupState.backupEnabled &&
                    <IconButton aria-label="Refresh" onClick={onRefresh} size="small" ><RefreshIcon/></IconButton>
                    }
                    <BackupToggle>
                        <Switch
                            checked={backupState.backupEnabled}
                            onChange={onEnableDisableBackup}
                            color="primary"
                            name="backupenabled"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        /> {backupState.backupEnabled ? "Enabled" : "Disabled"}
                    </BackupToggle>
                </BackupToggleParent>
            )}>

            <BackupContainer>
                <Tabs
                    value={backupTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}>
                    <Tab label="Folder" {...a11yProps(0)}/>
                    <Tab label="Settings" {...a11yProps(1)}/>
                </Tabs>

                <TabPanel value={backupTab} index={0}>
                    <BackupFolderStatus>
                        <BackupFolder>
                            <img src={folder} alt="folder"/>
                            {backupState.status.backupFolderLink && backupState.status.backupFolderLink !== "" &&
                            <Link target="_blank" rel="noreferrer"
                                  href={backupState.status.backupFolderLink}>{backupState.status.backupFolder}</Link>
                            }
                            {(!backupState.status.backupFolderLink || backupState.status.backupFolderLink === "") &&
                            <p>{backupState.status.backupFolder}</p>
                            }
                        </BackupFolder>
                        <HelpPopover text={`Once it's created by photos2drive, this folder can be moved or renamed within Google Drive.    
                                                        Metadata is stored within the folder so that photos2drive can find it 
                                                        no mater where is it located or what it is named.`}/>
                    </BackupFolderStatus>
                </TabPanel>
                <TabPanel value={backupTab} index={1}>
                    <BackupControlsParent>
                        <BackupControlsToggle>
                            <Switch
                                checked={backupState.findUpdated}
                                onChange={onEnableDisableFindUpdated}
                                color="primary"
                                name="findupdated"
                                disabled={!backupState.backupEnabled}
                                inputProps={{'aria-label': 'primary checkbox'}}
                            /> {"Backup Updated Photos"}
                            <HelpPopover
                                text={`If enabled, this will update backed up photos when the photo is changed on Google Photos.`}/>
                        </BackupControlsToggle>
                        <BackupControlsToggle>
                            <Switch
                                checked={backupState.createAlbums}
                                onChange={onEnableDisableCreateAlbums}
                                color="primary"
                                name="createalbums"
                                disabled={!backupState.backupEnabled}
                                inputProps={{'aria-label': 'primary checkbox'}}
                            /> {"Create Albums"}
                            <HelpPopover
                                text={`If enabled, this will create album folders within the drive folders.  Albums contents will be shortcuts to the photos they contain.`}/>
                        </BackupControlsToggle>
                        <BackupControlsToggle>
                            <Switch
                                checked={backupState.deleteRemoved}
                                onChange={onEnableDisableDeleteRemoved}
                                color="primary"
                                name="deleteremoved"
                                disabled={!backupState.backupEnabled}
                                inputProps={{'aria-label': 'primary checkbox'}}
                            /> {"Remove Deleted Photos"}
                            <HelpPopover
                                text={`If enabled, this will delete backed up photos if those photos are deleted on Google Photos.`}/>
                        </BackupControlsToggle>
                    </BackupControlsParent>
                </TabPanel>
            </BackupContainer>
        </P2DCard>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

const buildStatusLine = (backupState) => {
    if (!backupState.backupEnabled) {
        return "Backup photos and videos from Google Photos to Google Drive."
    }
    const {status} = backupState
    const inProgress = status.inDiscovery || status.itemsInBackupQueue > 0
    if (!inProgress) {
        if (status.lastBackup > 0) {
            const backedUpCount = status.newItemsToBackup + status.modifiedItemsToBackup
            return `${backedUpCount.toLocaleString()} new or modified items in last backup.  ${buildNextBackupTimeString(status.nextBackup)}`
        }
        return buildNextBackupTimeString(status.nextBackup)
    } else if (status.inDiscovery) {
        return "In Progress (Looking for items to backup)"
    }

    const totalItemsToBackup = status.newItemsToBackup + status.modifiedItemsToBackup
    const pctComplete = totalItemsToBackup > 0 ? Math.round((totalItemsToBackup - status.itemsInBackupQueue) / totalItemsToBackup * 100) : 100
    return `In Progress (${pctComplete}% Complete)`
}



const RELATIVE_TIME_CUTTOFF = 60*5

const buildNextBackupTimeString = (nextBackup) => {
    const nextBackupSeconds = nextBackup
    const currentTimeSeconds = new Date().getTime() / 1000
    // report using relative language
    const timeUntilBackup = nextBackupSeconds - currentTimeSeconds;
    if (timeUntilBackup < RELATIVE_TIME_CUTTOFF) {
        const minutes = Math.floor(timeUntilBackup / 60)
        const seconds = Math.floor(timeUntilBackup % 60)
        if (minutes >= 1) {
            return `Next backup will begin in ${minutes} minutes and ${seconds} seconds.  (Backup runs once per day)`
        } else {
            if (seconds > 0) {
                return `Next backup will begin in ${seconds} seconds.  (Backup runs once per day)`
            } else {
                return `Starting backup task... Press REFRESH to get updated status.`
            }
        }
    }
    const nextBackupDate = new Date(0)
    nextBackupDate.setUTCSeconds(nextBackupSeconds)
    return `Next backup is scheduled for ${nextBackupDate.toLocaleString()}.  (Backup runs once per day)`
}



export default Backup;
