
const API_PREFIX = '/api/backend'

export default {
    AUTH: {
        url: `${API_PREFIX}/auth`,
        method: 'POST'
    },
    LOGOUT: {
        url: `${API_PREFIX}/logout`,
        method: 'POST'
    },
    GET_BACKUP_STATE: {
        url: `${API_PREFIX}/backupstate`,
        method: 'GET'
    },
    GET_ATTENDANTS: {
        url: `${API_PREFIX}/attendants`,
        method: 'GET'
    },
    ADD_ATTENDANT: {
        url: `${API_PREFIX}/attendants`,
        method: 'POST'
    },
    REMOVE_ATTENDANT: {
        url: `${API_PREFIX}/attendants/[attendantId]`,
        method: 'DELETE'
    },
    GET_PERMISSION_STATE: {
        url: `${API_PREFIX}/permissionstate`,
        method: 'GET'
    },
    SET_BACKUP_STATE: {
        url: `${API_PREFIX}/backupstate`,
        method: 'POST'
    }
}
