import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {a_auth} from "../../user/state/actions";
import { Routes } from "../../app/routes";
import Loading from "../../common/components/Loading";

const LoadUser = (props) => {
    const { redirect=Routes.HOME.pathname } = props;

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(a_auth({
            accessCode: null,
            redirectUrl: null,
            requestType: 'ID_TOKEN'
        }, redirect, Routes.WELCOME.pathname, history))

    }, [redirect, dispatch, history]);

    return (
            <Loading/>
    );
}


export default LoadUser;
