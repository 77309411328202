import React from 'react';
import Login from '../../user/components/Login';
import { Routes } from "../routes";
import DetailsList from "./DetailsList";
import HelloBoard from "./HelloBoard";
import {WelcomeContainer, WelcomeDetails, WelcomeLogin} from "./Welcome.css";
import Terms from "./Terms";

const Welcome = () => {

    return (
        <WelcomeContainer>
            <HelloBoard/>
            <WelcomeDetails>
                <DetailsList/>
            </WelcomeDetails>
            <WelcomeLogin>
                <Login loginRedirect={Routes.HOME.pathname} loginText='Sign in with Google' scope='openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'/>
            </WelcomeLogin>
            <Terms preText="By signing in you agree to our "/>
        </WelcomeContainer>
    );
}

export default Welcome;
