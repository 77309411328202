import styled from "styled-components";

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const WelcomeDetails = styled.div`
  padding-top: 10px;
`
export const WelcomeLogin = styled.div`
  padding-top: 15px;
`

