import React from "react";
import styled from "styled-components";
import {TitleWhiteColor} from "../Colors";

/**** BEGIN STYLING ****/
const StyledTitle = styled.div`
  // local style
  display: inline;
  font-size: 2em;
  color: ${TitleWhiteColor};
  cursor: default;
`
/**** END STYLING ****/

const Title = () => {
    return (
        <StyledTitle>photos2drive</StyledTitle>
    );
}


export default Title;
