import React, {useCallback, useEffect} from "react";
import Attendant from "./Attendant";
import {useDispatch, useSelector} from "react-redux";
import {a_removeAttendantEpic, a_requestAttendants} from "../state/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {AttendantListContainer} from "./Attendant.css";
import NewAttendant from "./NewAttendant";

const AttendantList = () => {

    const dispatch = useDispatch()
    const {isLoading, attendants} = useSelector(state => state.attendantStore)

    useEffect(() => {
        dispatch(a_requestAttendants())
    }, [dispatch]);

    if (isLoading || !attendants)
        return (<AttendantListContainer><CircularProgress/></AttendantListContainer>);

    console.log(attendants)
    const attendantComp = attendants ? attendants.map((attendant) =>
        <Attendant
            key={attendant.id}
            id={attendant.id}
            albumName={attendant.albumName}
            fromDate={attendant.dateRangeFrom}
            toDate={attendant.dateRangeTo}
            itemsCount={attendant.itemsCount}/>
    ) : "";

    return (
        <AttendantListContainer>
            {attendantComp}
            <NewAttendant/>
        </AttendantListContainer>
    )
}

export default AttendantList;
