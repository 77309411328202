import {createReducer} from '@reduxjs/toolkit';

/**
 * ======================================================
 * REDUCER
 * ======================================================
 */
const userStore = createReducer({user: '_load_'}, {
    setUser: (state, action) => {
        return {
            user: action.payload
        }
    }
})

/**
 * ======================================================
 * HELPERS
 * ======================================================
 */

export default userStore
