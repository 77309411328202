import styled from "styled-components";
import {PrimaryColor} from "../Colors";

export const HeaderContainer = styled.div`
  background: ${PrimaryColor};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  height: 55px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`

export const HeaderLogout = styled.div`
  padding: 0 0 0 20px;
  font-size: .85em;
`
