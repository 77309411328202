import styled from "styled-components";
import {DarkTextColor} from "../../common/Colors";

export const PermissionContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  max-width: 400px;
`
export const PermissionMessage = styled.div`
    display: flex;
    padding-top: 15px;
    justify-items: center;
    align-items: center;
    text-align: center;
    color: ${DarkTextColor};
`
export const PermissionButton = styled.div`
    padding-top: 30px;
`
