import React from "react";
import drawing from "../../assets/images/p2d.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import {HelloBoardContainer} from "../../app/components/HelloBoard.css";
import styled from "styled-components";

export const LoadingProgress = styled.div`
  padding-bottom: 20px;
`


const Loading = ({text="Loading.  Please wait..."}) => {
    return (
        <HelloBoardContainer>
            <img src={drawing} alt="Photos2Drive Illustration"/>
            <LoadingProgress>{text}</LoadingProgress>
            <CircularProgress />
        </HelloBoardContainer>
    );
}


export default Loading;
