import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux'
import {combineReducers} from 'redux'
import { BrowserRouter } from 'react-router-dom'
import thunkMiddleware from 'redux-thunk'
import './index.css';
import App from './app/components/App';
import userStore from './user/state/reducer';
import backupStateStore from "./backup/state/reducer";
import attendantStore from "./attendants/state/reducer";
import homeStateStore from "./app/components/Home/state/reducer";
import {dialogStateStore, toastStateStore} from "./common/state/reducer";

const rootReducer = combineReducers({
    userStore,
    homeStateStore,
    backupStateStore,
    attendantStore,
    dialogStateStore,
    toastStateStore
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunkMiddleware)),
)

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
