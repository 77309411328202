import React from 'react';
import {useStore} from "react-redux";
import {PermissionButton, PermissionContainer, PermissionMessage} from "./Permission.css";
import {Routes} from "../../app/routes";
import Login from "../../user/components/Login";
import {SCOPES} from "../../common/Constants";
import drawing from "../../assets/images/p2d.png";

const Permission = () => {

    const store = useStore()
    const {userStore} = store.getState()
    const { email } = userStore.user

    return (
        <PermissionContainer>
            <img src={drawing} alt="Photos2Drive Illustration"/>
            <PermissionMessage>photos2drive needs permission to access Google Photos and Google Drive.</PermissionMessage>
            <PermissionButton>
                <Login loginRedirect={Routes.HOME.pathname} requireConsent='true' loginHint={email} loginText='Give Permission' scope={SCOPES}/>
            </PermissionButton>
        </PermissionContainer>
    );
}

export default Permission;
