import styled from "styled-components";
import {LabelColor} from "../../common/Colors";

export const HelloBoardContainer = styled.div`
  padding-top: 30px;
  font-size: 1.5em;
  color: ${LabelColor};
  
  // container layout
  display: flex;
  flex-direction: column;
  align-items: center;
`
