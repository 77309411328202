
export const Routes = {
    WELCOME: {
        pathname: '/welcome',
        loadUser: true
    },
    LOGOUT: {
        pathname: '/logout',
        loadUser: false
    },
    HOME: {
        pathname: '/home',
        loadUser: true
    },
    PROCESS_AUTH: {
        pathname: '/processAuth',
        loadUser: false
    },
}

export function getRoute(pathname) {
    let foundRoute = null
    for (let key in Routes) {
        if (Routes.hasOwnProperty(key)) {
            let checkRoute = Routes[key]
            if (checkRoute.pathname.toUpperCase() === pathname.toUpperCase()) {
                foundRoute = checkRoute
                break
            }
        }
    }
    if (!foundRoute) {
       foundRoute = Routes.HOME
    }
    return foundRoute
}
