import styled from "styled-components";
import {LabelColor} from "../../common/Colors";

export const BackupContainer = styled.div`
  margin-top: 10px;
  margin-left: 0px;
`
export const BackupToggleParent = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
export const BackupControlsParent = styled.div`
  margin-top: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-items: start;
`
export const BackupToggle = styled.div`
  color: ${LabelColor};
  font-weight: 500;
  margin-left: auto;
`
export const BackupControlsToggle = styled.div`
  color: ${LabelColor};
  padding-top: 5px;
  display: flex;
  align-items: center;
  
  div:last-child {
    margin-left: 5px;
  }
`

export const BackupFolderStatus = styled.div`
  margin-top: 15px;
  margin-left: 5px;
  display: flex;
  justify-items: flex-start;
  align-items: center;

  div:last-child {
    margin-left: 5px;
  }
`

export const BackupFolder = styled.div`
  padding: 4px 4px 4px 4px;
  display: flex;
  width: fit-content;
  justify-items: flex-start;
  align-items: center;

  a, p {
    margin-left: 5px;
  }
`
