import styled from "styled-components";
import {PrimaryButtonHoverColor, PrimaryButtonTextColor, PrimaryColor} from "../../common/Colors";

export const LoginButton = styled.div`
    height: 42px;
    background-color: ${PrimaryColor};
    border-radius: 4px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    cursor: default;
    display: flex;
    align-items: center;
    
    div {
      margin-left: 3px;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-items: center;
      
      img {
        width: 22px;
        height: 22px;
        flex: 1;
      }
    }
    
    p {
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 2px;
      color: ${PrimaryButtonTextColor};
      font-weight: 500;
      font-size: .90em;
      text-transform: uppercase;
      flex: 1;
    }
    &:hover {
      box-shadow: 0 0 6px ${PrimaryButtonHoverColor};
    }
    &:active {
      background: ${PrimaryButtonHoverColor};
    }
`
