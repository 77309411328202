import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import HelpIcon from "@material-ui/icons/Help";
import Popover from "@material-ui/core/Popover";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

/**** BEGIN STYLING ****/
const StyledText = styled.div`
  // local style
  display: block;
  max-width: 600px;
`
/**** END STYLING ****/


const HelpPopover = ({text="This is a popover"}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <HelpIcon style={{ marginTop: 5 , fontSize: 22 }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose} />
            <Popover
                id="help-info-popover-text"
                className={classes.popover}

                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <StyledText>{text}</StyledText>
            </Popover>
        </div>
    );
}


export default HelpPopover;
