import React, {useCallback, useRef} from "react";
import P2DCard from "../../common/components/P2DCard";
import albumImage from "../../assets/images/album_small.png";
import {Button, TextField} from "@material-ui/core";
import {AttendantActionsParent, AttendantContainer, AttendantDatesContainer} from "./Attendant.css";
import {a_addAttendantEpic} from "../state/actions";
import {useDispatch, useSelector} from "react-redux";

const NewAttendant = () => {
    const dispatch = useDispatch()
    const statusLine = "Automatically add photos to an album by date and will continue to monitor and add photos until removed."

    let albumName = useRef('');
    let fromDate = useRef('');
    let toDate = useRef('');
    const [addEnabled, setAddEnabled] = React.useState(false);
    const [fromError, setFromError] = React.useState('');

    const doValidate = () => {
        let theFromError = ""
        const allHaveValues = (!isEmpty(albumName.current.value) && !isEmpty(fromDate.current.value) && !isEmpty(toDate.current.value))
        if (!isEmpty(fromDate.current.value) && !isEmpty(toDate.current.value)) {
            const fromDateObject = new Date(fromDate.current.value)
            const toDateObject = new Date(toDate.current.value)
            if (fromDateObject <= toDateObject) {
                theFromError = ""
            } else {
                theFromError = "Invalid Date Range"
            }
        }
        setFromError(theFromError)
        setAddEnabled(allHaveValues && isEmpty(theFromError))
    }

    const handleAlbumChange = (event) => {
        doValidate()
    };

    const handleFromDateChange = (event) => {
        doValidate()
    };

    const handleToDateChange = (event) => {
        doValidate()
    };

    const onAdd = useCallback(() => {
        const tz = new Date().toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop();
        const fromDateWithTz = fromDate.current.value + " " + tz
        const toDateWithTz = toDate.current.value + " " + tz
        dispatch(a_addAttendantEpic(albumName.current.value, fromDateWithTz, toDateWithTz))
        albumName.current.value = ''
        fromDate.current.value = ''
        toDate.current.value = ''
    }, [dispatch, albumName, fromDate, toDate])

    return (
        <P2DCard
            cardImage={albumImage}
            cardTitle="Album Attendant"
            cardStatus={statusLine}
            cardActions={(
                <AttendantActionsParent>
                    <Button variant={"contained"} disabled={!addEnabled} onClick={onAdd}>Add</Button>
                </AttendantActionsParent>
            )}>
            <AttendantContainer>

                <TextField id="albumName" label="Album Name" size={"small"} fullWidth variant="outlined"
                           spellCheck={false}
                           onChange={handleAlbumChange}
                           inputRef={albumName}
                           InputLabelProps={{
                               shrink: true,
                           }}/>

                <AttendantDatesContainer>
                    <TextField id="fromDate" label="From" size={"small"} type={"date"} variant="outlined"
                               error={!isEmpty(fromError)}
                               helperText={fromError}
                               onChange={handleFromDateChange}
                               inputRef={fromDate}
                               InputLabelProps={{
                                   shrink: true,
                               }}/>
                    <TextField id="toDate" label="To" size={"small"} type={"date"} variant="outlined"
                               error={!isEmpty(fromError)}
                               onChange={handleToDateChange}
                               inputRef={toDate}
                               InputLabelProps={{
                                   shrink: true,
                               }}/>
                </AttendantDatesContainer>

            </AttendantContainer>

        </P2DCard>
    )
}

function isEmpty(str) {
    return (!str || str.length === 0 );
}

export default NewAttendant;
