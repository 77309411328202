import fetch from 'cross-fetch'
import {a_logout, a_setUser} from "../user/state/actions";
import {Routes} from "../app/routes";

export const getAPIPath = (url = '', params) => {
    let path = url;

    if (params) {
        Object.keys(params).forEach((key) => {
            if (url.indexOf(`[${key}]`) > -1) {
                path = path.replace(`[${key}]`, params[key]);
            } else {
                path = path.replace(`{${key}}`, `${key}=${params[key]}`);
            }
        });
    }

    // Parse unused query params
    // 1. Remove any unsued queryParams
    // 2. Remove empty ? or &
    // 3. Replace ?& to ? if queryParams exists
    return path
        .replace(/ *(&{||{){[^)]*\} */g, '')
        .replace(/[?|&]$/, '')
        .replace('?&', '?');
};

/**
 * Common method for calling backend api.
 *
 * Includes common processing for handling errors.
 *
 * @param api
 * @param options
 * @returns {Promise<unknown>}
 */
export function doFetch(api, dispatch, options = {}, userId = "") {

    return new Promise((resolve, reject) => {

        // build the query uri, headers, and body
        const { url, method } = api
        const body = (options.body) ? JSON.stringify(options.body) : null
        const { noLogout, params } = options
        const queryOptions = {
            method,
            headers: {
                "Content-Type": "application/json",
                "P2D_CSRF" : userId
            },
            body
        }
        const path = getAPIPath(url, params);

        // do the fetch with provided options
        fetch(path,queryOptions).then((response) => {

            // success
            if (response.status >= 200 && response.status <= 299 ) {
                response.json().then((json) => {
                    resolve(json)
                }).catch((err) => {
                    console.warn('Not a json resp body', err);
                    resolve()
                });
            }


            // auth failure - session expired.  logout
            else if (response.status === 401) {
                reject({statusCode: response.status, statusMessage: "auth failure", display: false})
                if (typeof window === 'undefined') return;
                if (!noLogout) {
                    dispatch(a_logout())
                }
            }

            else if (response.status === 403) {
                reject({statusCode: response.status, statusMessage: "forbidden", display: false})
                if (typeof window === 'undefined') return;
                dispatch(a_setUser('_empty_'))
                window.location = Routes.WELCOME.pathname
            }

            // client error.. parsable
            else {
                // the 400-499 status indicates are response we can parse
                response.json()
                    .then((rsp) => {
                        // response may have embedded message
                        const statusMessage = rsp.statusMessage ? rsp.statusMessage : "Unknown error."
                        reject({statusCode: response.status, statusMessage: statusMessage, display: true});
                    })
                    .catch((err) => {
                        reject({statusCode: response.status, statusMessage: "Unknown error.", display: true});
                    });
            }



        }, (error) => {
            reject({statusCode: -1, statusMessage: "Unknown error.", display: true});
        })
    })
}

