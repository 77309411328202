import {createReducer} from '@reduxjs/toolkit';

/**
 * ======================================================
 * REDUCER
 * ======================================================
 */
const backupStateStore = createReducer({isLoading: true, backupState: null}, {
    setBackupState: (state, action) => {
        return {
            isLoading: false,
            backupState: action.payload
        }
    },
    setLoadingBackupState: (state, action) => {
        return {
            isLoading: action.payload
        }
    }
})

/**
 * ======================================================
 * HELPERS
 * ======================================================
 */

export default backupStateStore
