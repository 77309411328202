import React from 'react';
import {useStore} from 'react-redux';
import {Switch, Route, useLocation} from "react-router-dom";
import { createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';
import ProcessAuth from "../../user/components/ProcessAuth";
import Welcome from "./Welcome";
import { Routes, getRoute } from "../routes"
import LoadUser from "../../user/components/LoadUser";
import Header from "../../common/components/Header";
import {AppContainer} from "./App.css";
import {LOAD_USER} from "../../common/Constants";
import {PrimaryColor, SecondaryColor} from "../../common/Colors";
import Toast, {TOAST_TYPES} from "../../common/components/Toast";
import Home from "./Home/Home";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: PrimaryColor,
        },
        secondary: {
            main: SecondaryColor,
        },
    },
});

const App = () => {
    const location = useLocation()
    const store = useStore()
    const {userStore} = store.getState()
    const user = userStore ? userStore.user : null
    const currentRoute = getRoute(location.pathname)

    console.log(location.pathname + " (" + currentRoute.pathname + ")")

    // load the user if needed
    if (user === LOAD_USER && currentRoute.loadUser) {
        // don't redirect to the welcome page, but redirect anywhere else
        const redirect = (currentRoute === Routes.WELCOME) ? Routes.HOME.pathname : currentRoute.pathname
        return (
            <ThemeProvider theme={theme}>
                <AppContainer>
                    <Header/>
                    <LoadUser redirect={redirect}/>
                </AppContainer>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <AppContainer>
                <Header/>
                <Toast type={TOAST_TYPES.ROOT_TOAST}/>
                <Switch>
                    <Route path={Routes.WELCOME.pathname}>
                        <Welcome/>
                    </Route>

                    <Route path={Routes.LOGOUT.pathname}>
                        <Welcome/>
                    </Route>

                    <Route path={Routes.PROCESS_AUTH.pathname}>
                        <ProcessAuth/>
                    </Route>

                    <Route>
                        <Home/>
                    </Route>
                </Switch>
            </AppContainer>
        </ThemeProvider>
    );
}

export default App;
