import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {a_setToastClosed} from "../state/actions";

export const TOAST_TYPES = {
    ROOT_TOAST: {
        id: 'ROOT_TOAST',
        anchorOrigin: {vertical:'top', horizontal: 'center' },
        autoHideDuration: null
    }
}

export const TOAST_SEV = {
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
    SUCCESS: "success"
}

const Toast = (props) => {
    const { type, children } = props
    let toastState = useSelector(state => state.toastStateStore)[type.id]
    const dispatch = useDispatch()

    const onClose = useCallback(() => {
        dispatch(a_setToastClosed(type))
    }, [dispatch, type])

    if (!toastState) {
        return (<div></div>)
    }

    return (
        <Snackbar autoHideDuration={type.autoHideDuration} anchorOrigin={type.anchorOrigin}
            open={toastState.openState}
            onClose={onClose}
            scroll="paper"
            maxwidth="md"
        >
            <Alert elevation={6} variant="filled" severity={toastState.severity} onClose={onClose}>
                {toastState.message}
                {children}
            </Alert>
        </Snackbar>
    )

}

export default Toast
