import React, {useCallback} from "react";
import P2DCard from "../../common/components/P2DCard";
import albumImage from "../../assets/images/album_small.png";
import {Button, TextField, withStyles} from "@material-ui/core";
import {AttendantActionsParent, AttendantContainer, AttendantDatesContainer} from "./Attendant.css";
import {a_removeAttendantEpic} from "../state/actions";
import {useDispatch} from "react-redux";

const Attendant = (props) => {
    const statusLine = props.itemsCount + " items in album"

    const dispatch = useDispatch()
    const onRemove = useCallback(() => {
        // dispatch logout action.  Logout will affect history.. so pass it in
        dispatch(a_removeAttendantEpic(props.id))
    }, [dispatch])


    const StyledButton = withStyles({
        root: {
            color: 'white',
            background: '#eb5757'
        }
    })(Button);

    return (
        <P2DCard
            cardImage={albumImage}
            cardTitle="Album Attendant"
            cardStatus={statusLine}
            cardActions={(
                <AttendantActionsParent>
                    <StyledButton variant={"contained"} onClick={onRemove}>Remove</StyledButton>
                </AttendantActionsParent>
            )}>
            <AttendantContainer>

                <TextField id={"albumName-" + props.id} label="Album Name" size={"small"} fullWidth
                           spellCheck={false}
                           InputProps={{
                               readOnly: true,
                           }}
                           defaultValue={props.albumName}/>

                <AttendantDatesContainer>
                    <TextField id={"fromDate-"+ props.id} label="From" size={"small"}
                               spellCheck={false}
                               InputProps={{
                                   readOnly: true,
                               }}
                               defaultValue={props.fromDate}/>
                    <TextField id={"toDate-"+ props.id}  label="To" size={"small"}
                               InputProps={{
                                   readOnly: true,
                               }}
                               defaultValue={props.toDate}/>
                </AttendantDatesContainer>

            </AttendantContainer>

        </P2DCard>
    )
}

export default Attendant;
