import React from 'react';
import {useCallback} from 'react';
import {useDispatch} from "react-redux";
import {Button} from "@material-ui/core";
import {a_logout} from "../state/actions";

const Logout = () => {

    const dispatch = useDispatch()
    const onLogout = useCallback((response) => {
        // dispatch logout action.  Logout will affect history.. so pass it in
        dispatch(a_logout())
    }, [dispatch])

    return (
        <Button variant={"contained"} onClick={onLogout}>Logout</Button>
    )
}

export default Logout;
