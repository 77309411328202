import React, {useEffect} from 'react';
import {HomeContainer} from "./Home.css";
import Backup from "../../../backup/components/Backup";
import {useDispatch, useSelector} from "react-redux";
import Permission from "../../../backup/components/Permission";
import {a_requestPermissionState} from "./state/actions";
import Loading from "../../../common/components/Loading";
import AttendantList from "../../../attendants/components/AttendantList";

const Home = () => {

    const dispatch = useDispatch()
    const { isLoading , permissionState } = useSelector(state => state.homeStateStore)

    useEffect(() => {
        dispatch(a_requestPermissionState())
    }, [dispatch]);

    if (isLoading)
        return (<HomeContainer><Loading/></HomeContainer>);

    if (!permissionState.permissionGranted)
        return (<HomeContainer><Permission/></HomeContainer>);

    return (
        <HomeContainer>
            <Backup/>
            <AttendantList/>
        </HomeContainer>
    )
}

export default Home;
