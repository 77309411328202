import {createReducer} from '@reduxjs/toolkit';

/**
 * ======================================================
 * REDUCER
 * ======================================================
 */
const homeStateStore = createReducer({isLoading: true, permissionState: null}, {
    setPermissionState: (state, action) => {
        return {
            isLoading: false,
            permissionState: action.payload
        }
    },
    setLoadingHomeState: (state, action) => {
        return {
            isLoading: action.payload
        }
    }
})

/**
 * ======================================================
 * HELPERS
 * ======================================================
 */

export default homeStateStore
