export const PrimaryColor = '#225CE0'
export const TitleWhiteColor = '#FFFFFF'
export const BetaOrangeColor = '#ffc749'
export const PrimaryButtonTextColor = '#FFFFFF'
export const PrimaryButtonHoverColor = '#0943C7'
export const SecondaryButtonTextColor = '#000000'
export const LabelColor = '#333333'
export const DarkTextColor = '#4F4F4F'
export const SecondaryColor = '#dcdcdc'
export const DisabledText = '#828282'
