import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import styled from "styled-components";

const DetailsListContainer = styled(List)`
  max-width: 360px;
`

export default function DetailsList() {
    return (
        <DetailsListContainer>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Backup" secondary="photos2drive will backup all your Google Photos to Google Drive." />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <WorkIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Organize" secondary="photos2drive will organize the folders in Google Drive by date taken." />
            </ListItem>
        </DetailsListContainer>
    );
}
