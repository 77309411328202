import {createAction} from "@reduxjs/toolkit";
import {doFetch} from "../../api/dofetch";
import BackendApi from "../../api/backend"
import {a_setToastOpen} from "../../common/state/actions";
import {TOAST_SEV, TOAST_TYPES} from "../../common/components/Toast";

/**
 * ======================================================
 * THUNK ACTIONS
 * ======================================================
 */
export function a_requestAttendants() {
    return (dispatch, getState) => {
        const {userStore} = getState()
        dispatch(a_setLoading(true))
        return doFetch(BackendApi.GET_ATTENDANTS, dispatch, {}, userStore.user.id)
            .then(json => {
                dispatch(a_setAttendants(json.attendants))
            })
            .catch(err => {
                dispatch(a_setLoading(false))
            })
    }
}

export function a_addAttendantEpic(albumName, dateRangeFrom, dateRangeTo) {
    return (dispatch, getState) => {
        const {userStore} = getState()
        return doFetch(BackendApi.ADD_ATTENDANT, dispatch, { body:
                {
                    "albumName": albumName,
                    "dateRangeFrom": dateRangeFrom,
                    "dateRangeTo": dateRangeTo
                } }, userStore.user.id)
            .then(json => {
                dispatch(a_addAttendant(json))
            })
            .catch(err => {
                if (err && err.display) {
                    dispatch(a_setToastOpen(TOAST_TYPES.ROOT_TOAST, err.statusMessage, TOAST_SEV.ERROR, true))
                }
            })
    }
}

export function a_removeAttendantEpic(attendantId) {
    return (dispatch, getState) => {
        const {userStore} = getState()
        return doFetch(BackendApi.REMOVE_ATTENDANT, dispatch, { params:
                {
                    "attendantId": attendantId
                } }, userStore.user.id)
            .then(json => {
                dispatch(a_removeAttendant(attendantId))
            })
            .catch(err => {
                if (err && err.display) {
                    dispatch(a_setToastOpen(TOAST_TYPES.ROOT_TOAST, err.statusMessage, TOAST_SEV.ERROR, true))
                }
            })
    }
}

/**
 * ======================================================
 * STATIC ACTIONS
 * ======================================================
 */
export const a_setLoading = createAction('setLoadingAttendants')
export const a_setAttendants = createAction('setAttendants')
export const a_removeAttendant = createAction('removeAttendant')
export const a_addAttendant = createAction('addAttendant')


