import React from 'react';
import drawing from "../../assets/images/p2d.png";
import {HelloBoardContainer} from "./HelloBoard.css";
import {useStore} from "react-redux";
import {EMPTY_USER, LOAD_USER} from "../../common/Constants";

const HelloBoard = () => {
    const store = useStore()
    const {userStore} = store.getState()
    const user = userStore ? (userStore.user !== LOAD_USER && userStore.user !== EMPTY_USER) ? userStore.user : null : null

    const welcomeString = user ? `Welcome ${user.firstName}!` : "Welcome to photos2drive!"
    return (
        <HelloBoardContainer>
            <img src={drawing} alt="Photos2Drive Illustration"/>
            <div>{welcomeString}</div>
        </HelloBoardContainer>
    );
}

export default HelloBoard;
