import {createAction} from "@reduxjs/toolkit";
import {doFetch} from "../../api/dofetch";
import BackendApi from "../../api/backend"

/**
 * ======================================================
 * THUNK ACTIONS
 * ======================================================
 */
export function a_requestBackupState() {
    return (dispatch, getState) => {
        const {userStore} = getState()
        dispatch(a_setLoading(true))
        return doFetch(BackendApi.GET_BACKUP_STATE, dispatch, {}, userStore.user.id)
            .then(json => {
                dispatch(a_setBackupState(json))
            })
            .catch(err => {
                dispatch(a_setLoading(false))
            })
    }
}

export function a_updateBackupState(backupEnabled, findUpdated, deleteRemoved, createAlbums) {
    return (dispatch, getState) => {
        const {userStore} = getState()
        dispatch(a_setLoading(true))
        return doFetch(BackendApi.SET_BACKUP_STATE, dispatch, { body:
                {
                    "backupEnabled": backupEnabled,
                    "findUpdated": findUpdated,
                    "deleteRemoved": deleteRemoved,
                    "createAlbums" : createAlbums
                } }, userStore.user.id)
            .then(json => {
                dispatch(a_setBackupState(json))
            })
            .catch(err => {
                dispatch(a_setLoading(false))
            })
    }
}

export function a_requestFindUpdatedEnable(enable) {
    return (dispatch, getState) => {
        const {userStore} = getState()
        dispatch(a_setLoading(true))
        return doFetch(BackendApi.SET_BACKUP_STATE, dispatch, { body: {"findUpdated":enable} }, userStore.user.id)
            .then(json => {
                dispatch(a_setBackupState(json))
            })
            .catch(err => {
                dispatch(a_setLoading(false))
            })
    }
}

export function a_requestDeleteRemovedEnable(enable) {
    return (dispatch, getState) => {
        const {userStore} = getState()
        dispatch(a_setLoading(true))
        return doFetch(BackendApi.SET_BACKUP_STATE, dispatch, { body: {"deleteRemoved":enable} }, userStore.user.id)
            .then(json => {
                dispatch(a_setBackupState(json))
            })
            .catch(err => {
                dispatch(a_setLoading(false))
            })
    }
}


/**
 * ======================================================
 * STATIC ACTIONS
 * ======================================================
 */
export const a_setLoading = createAction('setLoadingBackupState')
export const a_setBackupState = createAction('setBackupState')


