import {createReducer} from '@reduxjs/toolkit';

/**
 * ======================================================
 * REDUCERS
 * ======================================================
 */
export const dialogStateStore = createReducer({}, {
    setDialogOpen: (state, action) => {
        return {
            ...state,
            ...action.payload
        }
    },
    setDialogClosed: (state, action) => {
        let closed = {
            ...state
        }
        delete closed[action.payload]

        return closed
    }
})

export const toastStateStore = createReducer({}, {
    setToastOpen: (state, action) => {
        return {
            ...state,
            ...action.payload
        }
    },
    setToastClosed: (state, action) => {
        let closed = {
            ...state
        }
        delete closed[action.payload]

        return closed
    }
})


