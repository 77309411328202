import styled from "styled-components";

export const AttendantListContainer = styled.div`
  margin-top: 10px;
  margin-left: 0px;
`

export const AttendantActionsParent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`

export const AttendantContainer = styled.div`
  margin-top: 30px;
  margin-left: 0px;
`

export const AttendantDatesContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  > * {
    flex-grow: 1;
    &:not(:first-child) {
      flex-grow: 1;
      margin-left: 10px;
    }
  }
`
