import styled from "styled-components";

export const TermsContainer = styled.div`
  padding-top: 20px;
`

export const TermsBody = styled.div`
  li {
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h1,h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
`
