import styled from "styled-components";
import {LabelColor} from "../../common/Colors";

export const P2DCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
  margin-top: 20px;
  max-width: 375px;
  min-width: 375px;
`

export const P2DCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:first-child, :last-child) {
    padding: 0 10px;
  }
  
`

export const P2DCardBody = styled.div`
`

export const P2DCardTitleText = styled.div`
  font-size: 1.5em;
  color: ${LabelColor}
`

export const P2DCardTitleAction = styled.div`
  flex-grow: 1;
`

export const P2DCardStatus = styled.div`
  display: flex;
  flex-direction: row;
  font-size: .85em;
  padding-top: 10px;
`


