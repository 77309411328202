import {createAction} from "@reduxjs/toolkit";
import {doFetch} from "../../../../api/dofetch";
import BackendApi from "../../../../api/backend"


/**
 * ======================================================
 * THUNK ACTIONS
 * ======================================================
 */
export function a_requestPermissionState() {
    return (dispatch, getState) => {
        const {userStore} = getState()
        dispatch(a_setLoading(true))
        return doFetch(BackendApi.GET_PERMISSION_STATE, dispatch, {}, userStore.user.id)
            .then(json => {
                dispatch(a_setPermissionState(json))
            })
            .catch(err => {
                dispatch(a_setLoading(false))
            })
    }
}


/**
 * ======================================================
 * STATIC ACTIONS
 * ======================================================
 */
export const a_setLoading = createAction('setLoadingHomeState')
export const a_setPermissionState = createAction('setPermissionState')


