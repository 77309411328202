import React from 'react';
import {useCallback} from 'react';
import {Routes} from "../../app/routes";
import googleSvg from "../../assets/images/GoogleGLogo.svg";
import {CLIENT_ID} from "../../common/Constants";
import {
    LoginButton
} from "./Login.css";

const Login = (props) => {

    const { loginRedirect, scope, loginText, requireConsent=false, loginHint='' } = props;

    /**
     * Callback which uses google oath2 to obtain an access token from google using our client id.
     *
     * Code taken from https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow#redirecting
     */
    const onLogin = useCallback(() => {

        // Google's OAuth 2.0 endpoint for requesting an access token
        const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        const form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);

        // Parameters to pass to OAuth 2.0 endpoint.
        const params = {'client_id': CLIENT_ID,
            'redirect_uri': redirectUrl,
            'response_type': 'code',
            'scope': scope,
            'include_granted_scopes': 'true',
            'access_type': 'offline',
            'prompt': requireConsent ? 'consent' : 'select_account',
            'login_hint': loginHint,
            'state': loginRedirect};

        // Add form parameters as hidden input values.
        for (let p in params) {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', p);
            input.setAttribute('value', params[p]);
            form.appendChild(input);
        }

        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
    }, [loginRedirect, scope, requireConsent, loginHint])

    return (
            <LoginButton onClick={onLogin}>
                <div><img src={googleSvg} alt={"signin and/or provided permission"}/></div>
                <p>{loginText}</p>
            </LoginButton>
    );
}


export const redirectUrl = window.location.protocol+'//'+window.location.host+Routes.PROCESS_AUTH.pathname;
export default Login;
