import React, {useEffect} from 'react';
import {useDispatch } from "react-redux";
import {a_auth} from "../state/actions";
import {useQuery} from "../../common/utils";
import {redirectUrl} from "./Login"
import { useHistory } from 'react-router-dom'
import Loading from "../../common/components/Loading";
import { Routes } from "../../app/routes";

const ProcessAuth = () => {
    const dispatch = useDispatch()
    const query = useQuery()
    const history = useHistory()

    const selfRedirect = query.get('state').toString()
    useEffect(() => {
        dispatch(a_auth(getAuthRequestFromQuery(query), selfRedirect, Routes.WELCOME.pathname, history))
    }, [selfRedirect, dispatch, query, history])

    return (
        <div>
            <Loading/>
        </div>
    );
}

function getAuthRequestFromQuery(query) {
    return {
        accessCode: query.get('code'),
        redirectUrl: redirectUrl,
        scope: query.get('scope'),
        requestType: 'AUTH_CODE'
    }
}

export default ProcessAuth;
