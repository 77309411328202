import React from "react";
import Title from "./Title";
import {useStore} from "react-redux";
import Logout from "../../user/components/Logout";
import {
    HeaderContainer,
    HeaderLogout
} from "./Header.css";
import {EMPTY_USER, LOAD_USER} from "../Constants";

const Header = () => {
    const store = useStore()
    const {userStore} = store.getState()
    const hasUser = userStore && userStore.user !== LOAD_USER && userStore.user !== EMPTY_USER

    return (
        <HeaderContainer>
            <Title/>
            {hasUser &&
            <HeaderLogout>
                <Logout/>
            </HeaderLogout>
            }
        </HeaderContainer>
    );
}

export default Header;
