import React from "react";

import {
    P2DCardContainer,
    P2DCardHeader,
    P2DCardTitleText,
    P2DCardTitleAction,
    P2DCardStatus, P2DCardBody,
} from "./P2DCard.css";

const P2DCard = (props) => {
    const {cardImage} = props
    const {cardTitle} = props
    const {children} = props
    const {cardStatus} = props
    const {cardActions} = props

    return(
        <P2DCardContainer>
            <P2DCardHeader>
                <img src={cardImage} alt={cardTitle}/>
                <P2DCardTitleText>{cardTitle}</P2DCardTitleText>
                <P2DCardTitleAction>{cardActions}</P2DCardTitleAction>
            </P2DCardHeader>
            <P2DCardStatus>
                {cardStatus}
            </P2DCardStatus>
            <P2DCardBody>
                {children}
            </P2DCardBody>
        </P2DCardContainer>
    )
}

export default P2DCard;
