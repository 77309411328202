import {createAction} from "@reduxjs/toolkit";
import {TOAST_SEV} from "../components/Toast";

/**
 * ======================================================
 * STATIC ACTIONS
 * ======================================================
 */
export const a_setDialogOpen = createAction('setDialogOpen', function prepare(id) {
    let payload_body = {
        [id]: {
            openState: true
        }
    }
    return {
        payload: payload_body
    }
})
export const a_setDialogClosed = createAction('setDialogClosed', function prepare(id) {
    return {
        payload: id
    }
})

export const a_setToastOpen = createAction('setToastOpen', function prepare(type, message, severity=TOAST_SEV.INFO, openState=true) {
    let payload_body = {
        [type.id]: {
            openState: openState,
            message: message,
            severity: severity
        }
    }
    return {
        payload: payload_body
    }
})

export const a_setToastClosed = createAction('setToastClosed', function prepare(type) {
    return {
        payload: type.id
    }
})
